import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import TextField from '@mui/material/TextField';

import {
  userInfoFormContent,
  companyInfoFormContent,
  legalFirmInfoFormContent,
  accountingFirmInfoFormContent,
  extraUserFormContent,
} from './utils/formFieldsContents';

import ReviewDropdown from '../409aInfo/ReviewDropdown';
import ReviewInfoTile from '../409aInfo/ReviewInfoTile';
import signGraphic from '../../images/SignGraphic.svg';

import { ReactComponent as WarningIcon } from '../../images/icons/warning_icon.svg';
import './ReviewAndSign.scss';


export default function ReviewAndSign({
  steps,
  wizSteps,
  setWizSteps,
  openNewWizard,
  isFormCompleted,
  // Wiz1
  userInfoInput,
  companyInfoInput,
  legalFirmInfoInput,
  accountingFirmInfoInput,
  inputHasError,
  userSignature,
  setUserSignature,
  showErrors,
  setShowErrors,
  setReviewPageHasBeenVisited,
}) {
  const [signatureTextFieldHasError, setSignatureTextFieldHasError] = useState(false);

  useEffect(() => setReviewPageHasBeenVisited(true), []);

  if (wizSteps.currentStep === 1) {
    return (
      <div className="ReviewAndSign Review">
        {
          (showErrors && !isFormCompleted) && (
            <div className="warning-block">
              <div className="warning-header">
                <WarningIcon className="warning-icon" />
                <h2>Failed to submit information</h2>
              </div>
              <p>
                All required information must be provided before you can submit your information.
              </p>
            </div>
          )
        }
        <div className="page-header-row">
          <h4>Review and submit information</h4>
          <Button
            className="top-submit-info-btn"
            onClick={() => {
              if (!isFormCompleted) {
                setShowErrors(true);
              } else {
                setShowErrors(false);
                setWizSteps({ ...wizSteps, currentStep: wizSteps.currentStep + 1 });
              }
            }}
          >
            Submit information
          </Button>
        </div>
        <hr />
        <ReviewDropdown
          steps={steps}
          openNewWizard={openNewWizard}
          activeWiz={0}
          currentStep={1}
          headline="Your information"
          content={(
            <div className="review-tiles-list">
              {Object.keys(userInfoInput).map((input, i) => {
                return (
                  <ReviewInfoTile
                    key={userInfoFormContent[i].name}
                    activeWizard={0}
                    wizardTotalSteps={steps[0].totalSteps()}
                    openNewWizard={openNewWizard}
                    currentStep={1}
                    title={userInfoFormContent[i].label}
                    data={userInfoInput[input]}
                    inputHasError={inputHasError[userInfoFormContent[i].name]}
                    showErrors={showErrors}
                    invalidInputHelperText={userInfoFormContent[i].helperText}
                    isDataOptional={userInfoFormContent[i].isOptional}
                  />
                );
              })}
            </div>
          )}
        />
        <ReviewDropdown
          steps={steps}
          openNewWizard={openNewWizard}
          activeWiz={0}
          currentStep={2}
          headline="Your company's information"
          content={(
            <div className="review-tiles-list">
              {Object.keys(companyInfoInput).map((input, i) => {
                if (input !== 'extraUsers') {
                  return (
                    <ReviewInfoTile
                      key={companyInfoFormContent[i]?.name}
                      activeWizard={0}
                      wizardTotalSteps={steps[0].totalSteps()}
                      openNewWizard={openNewWizard}
                      currentStep={2}
                      title={companyInfoFormContent[i]?.label}
                      data={companyInfoInput[input]}
                      showErrors={showErrors}
                      isDataOptional={companyInfoFormContent[i]?.isOptional}
                      invalidInputHelperText={companyInfoFormContent[i]?.helperText}
                      inputHasError={inputHasError[companyInfoFormContent[i]?.name]}
                    />
                  );
                }
                return null;
              })}
            </div>
          )}
        />
        {companyInfoInput.extraUsers.map((extraUser) => {
          return (
            <ReviewDropdown
              key={extraUser.id}
              steps={steps}
              openNewWizard={openNewWizard}
              activeWiz={0}
              currentStep={2}
              headline="Additional user"
              content={(
                <div className="review-tiles-list">
                  {Object.keys(extraUser).map((input, i) => {
                    if (input !== 'id') {
                      return (
                        <ReviewInfoTile
                          key={extraUserFormContent[i]?.name}
                          activeWizard={0}
                          wizardTotalSteps={steps[0]?.totalSteps()}
                          openNewWizard={openNewWizard}
                          currentStep={2}
                          title={extraUserFormContent[i]?.label}
                          data={extraUser[input]}
                          showErrors={showErrors}
                          isDataOptional={extraUserFormContent[i]?.isOptional}
                          invalidInputHelperText={extraUserFormContent[i]?.helperText}
                          inputHasError={inputHasError[extraUserFormContent[i]?.name]}
                        />
                      );
                    }
                    return null;
                  })}
                </div>
              )}
            />
          );
        })}
        <ReviewDropdown
          steps={steps}
          openNewWizard={openNewWizard}
          activeWiz={0}
          currentStep={3}
          headline="Your accountant and legal firm"
          content={(
            <>
              <h6 className="sub-title">Your legal firm</h6>
              <div className="review-tiles-list">
                {Object.keys(legalFirmInfoInput).map((input, i) => {
                  if (input !== 'legalAdvisorPermission') {
                    return (
                      <ReviewInfoTile
                        key={legalFirmInfoFormContent[i]?.name}
                        activeWizard={0}
                        wizardTotalSteps={steps[0]?.totalSteps()}
                        openNewWizard={openNewWizard}
                        currentStep={3}
                        title={legalFirmInfoFormContent[i]?.label}
                        data={legalFirmInfoInput[input]}
                        showErrors={showErrors}
                        isDataOptional={legalFirmInfoFormContent[i]?.isOptional}
                        invalidInputHelperText={legalFirmInfoFormContent[i]?.helperText}
                        inputHasError={inputHasError[legalFirmInfoFormContent[i]?.name]}
                      />
                    );
                  }
                  return null;
                })}
              </div>
              <h6 className="sub-title">Your accounting firm</h6>
              <div className="review-tiles-list">
                {Object.keys(accountingFirmInfoInput).map((input, i) => {
                  if (input !== 'accountingAdvisorPermission') {
                    return (
                      <ReviewInfoTile
                        key={accountingFirmInfoFormContent[i].name}
                        activeWizard={0}
                        wizardTotalSteps={steps[0].totalSteps()}
                        openNewWizard={openNewWizard}
                        currentStep={3}
                        title={accountingFirmInfoFormContent[i].label}
                        data={accountingFirmInfoInput[input]}
                        showErrors={showErrors}
                        isDataOptional={accountingFirmInfoFormContent[i].isOptional}
                        invalidInputHelperText={accountingFirmInfoFormContent[i].helperText}
                        inputHasError={inputHasError[accountingFirmInfoFormContent[i].name]}
                      />
                    );
                  }
                  return null;
                })}
              </div>
            </>
          )}
        />
      </div>
    );
  }

  if (wizSteps.currentStep === 2) {
    return (
      <div className="ReviewAndSign Sign">
        <h4>Sign contract</h4>
        <div className="sign-info-box">
          <img src={signGraphic} alt="" />
          <p className="sign-info-text">
            By entering my name and clicking save,
            I am certifying that the information
            I have provided is true and correct to the best of my knowledge.
            I am authorized to sign on behalf of this company and give initio™
            the legal right to use the information I have provided to create financially
            binding documentation such as a 409A valuation.
          </p>
        </div>
        <FormControl>
          <TextField
            label="Your name"
            type="text"
            onFocus={() => setSignatureTextFieldHasError(false)}
            value={userSignature || ''}
            onChange={(e) => {
              e.preventDefault();
              setUserSignature(e.target.value);
            }}
            onBlur={(e) => {
              e.preventDefault();
              setUserSignature(e.target.value.trim());
              if (e.target.value.trim() === '') {
                setSignatureTextFieldHasError(true);
              }
            }}
            error={signatureTextFieldHasError}
          />
          <FormHelperText style={{
            visibility: signatureTextFieldHasError ? 'visible' : 'hidden',
            marginBottom: 0,
          }}
          >
            Please enter your name
          </FormHelperText>
        </FormControl>
      </div>
    );
  }

  return (
    <div className="ReviewAndSign">
      <h1>ReviewAndSign - error</h1>
    </div>
  );
}

ReviewAndSign.propTypes = {
  isFormCompleted: PropTypes.bool.isRequired,
  userInfoInput: PropTypes.object.isRequired,
  companyInfoInput: PropTypes.object.isRequired,
  legalFirmInfoInput: PropTypes.object.isRequired,
  accountingFirmInfoInput: PropTypes.object.isRequired,
  inputHasError: PropTypes.object.isRequired,
  openNewWizard: PropTypes.func.isRequired,
  steps: PropTypes.arrayOf(PropTypes.object).isRequired,
  wizSteps: PropTypes.object.isRequired,
  setWizSteps: PropTypes.func.isRequired,
  userSignature: PropTypes.string,
  setUserSignature: PropTypes.func.isRequired,
  showErrors: PropTypes.bool.isRequired,
  setShowErrors: PropTypes.func.isRequired,
  setReviewPageHasBeenVisited: PropTypes.func.isRequired,
};
