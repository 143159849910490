import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';

import {
  UserDataContext,
  UserStateContext,
  ErrorMessageContext,
  NavWidthContext,
} from '.';

export default function ContextsProvider({ children }) {
  const [userData, setUserData] = useState({});
  const [userState, setUserState] = useState({});
  const [showErrorMessage, setShowErrorMessage] = useState('');
  const [navWidth, setNavWidth] = useState(null);

  const userDataProviderValue = useMemo(() => ({ userData, setUserData }), [userData]);
  const userStateProviderValue = useMemo(() => ({ userState, setUserState }), [userState]);
  const showErrorMessageProviderValue = useMemo(() => ({ showErrorMessage, setShowErrorMessage }), [showErrorMessage]);
  const navWidthProviderValue = useMemo(() => ({ navWidth, setNavWidth }), [navWidth]);

  return (
    <UserDataContext.Provider value={userDataProviderValue}>
      <UserStateContext.Provider value={userStateProviderValue}>
        <ErrorMessageContext.Provider value={showErrorMessageProviderValue}>
          <NavWidthContext.Provider value={navWidthProviderValue}>
            {children}
          </NavWidthContext.Provider>
        </ErrorMessageContext.Provider>
      </UserStateContext.Provider>
    </UserDataContext.Provider>
  );
}

ContextsProvider.propTypes = { children: PropTypes.element.isRequired };
